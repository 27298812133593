import React from "react";
import { useState, useEffect } from "react";
import TextTransition, { presets } from 'react-text-transition';
import './preload.css'
const TEXTS = ['Explore', 'Create', 'Design', '      '];

const Preload = () => {
    const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

    return ( 
      <div className="preload" style={{backgroundColor: "black"}}>
        <div className="preload-text-wrapper">
            <div className="preload-text">
                <h1 className="transitioning-text">    
                    <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
                </h1>
            </div>  
        </div>
      </div>
    )
}
export default Preload;
