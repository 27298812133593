import React, { useState } from "react";
import './navbar.css';
import { Link } from "react-router-dom";
import { TfiArrowTopRight } from "react-icons/tfi";
const Navbar = ()=>{


    return (
        <div className="bg-black">
            <div className="wrapper">
                <div className="navigation" data-scroll-section >
                    <div className="navigation-logo text-xxl">
                        <span><Link className="nav-Link-react-router" to="/"><h3 className="nav-name">Ankit.<span className="hard-grey">des</span></h3></Link></span>
                    </div>
                    <div className="navigation-links text-m">
                        <ul className="over-hide">
                            <span><Link className="nav-Link-react-router" to="/about"><li>About Me</li></Link></span>
                            <span><Link className="nav-Link-react-router" to="/work"><li>My Work</li></Link></span>
                            <span><Link className="nav-Link-react-router" to="/contact"><li>Let's Connect <span className="pl-1"> <TfiArrowTopRight style={{marginBottom: "-4px"}}/></span></li></Link></span>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Navbar;