import React from "react";

const Card = ({title, image, name, description}) => {

    return (
        <div className="card">
            <div className="profession"><p>{title}</p></div>
            <div className="image-icon mt-one">
                <div className="icon mb-one"><img src={image} /></div>
                <div className="name text-1-5">{name}</div>
            </div>
            <div className="description">{description}  </div>
        </div>
    );

}
export default Card;