import React, {useState} from "react";
import img_icon from '../../../assets/images/footer_img-icon.png'
const EmailFooter = () => {
    const[data, setData] = useState({
        title: "Let's work together!",
        email: "deshwal21ankit@gmail.com",
        desc1: "Let’s talk about a new",
        desc2: "project or an idea you",
        desc3: "may have!",
          
        socials: {
            Behance: "a",
            Linkedin: "adfs",
            Instagram: "d",
            Github: "e",

        }
    })
    return(
        <div style={{backgroundColor: 'white', color: 'black', paddingTop: '4rem'}}>
            <section className="wrapper">
                <div className="footer-email-talk ">
                    <div className="footer-inner-title-icon line-xxl syne">
                    
                        <span className="footer-desc1 text-4 syne"> <img className="footer-inner-title-icon-img" src={img_icon} /> {data.desc1}</span>
                        <span className="footer-desc2 text-4 syne">{data.desc2}</span>
                        <span className="footer-desc3 text-4 syne">{data.desc3}</span>
                        
                    </div>
                    <div className="footer-inner-email-wrapper">
                        <div className="footer-inner-email">
                            <p><a style={{color: "#121212"}} href="mailto: deshwal21ankit@gmail.com">{data.email}</a> </p>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default EmailFooter;