import React, { useEffect } from "react";
import { useState } from "react";
import './projectRef.css';
import { ImArrowDownRight2 } from "react-icons/im";
import { FaBeer } from 'react-icons/fa';
import Button from "../../shared/Button/button";
import Project1Img from '../../assets/project_images/campus_svg.svg';
import Project2Img from '../../assets/project_images/swiggy_svg.svg';
import Project3Img from '../../assets/project_images/niwwrd_svg1.svg';
import Clustered_GIF from "../../shared/clusteredGIF/gif";
import {ImArrowUpRight2}from 'react-icons/im';
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { transform } from "framer-motion";
import SmrutiImg from '../../assets/testimonials/smruti.png';
import kuljinderImg from '../../assets/testimonials/kuljinder.png';
import SomannaImg from '../../assets/testimonials/sommana.png';
import SrinivasaImg from '../../assets/testimonials/srinivasa.png'
import Marquee from "react-fast-marquee";
import Card from "../../shared/card/card";
const ProjectRef = (props)  =>{
    let t3 = null;
    useEffect(() => {

        let ctx = gsap.context(() => {
    
    
            gsap.registerPlugin(ScrollTrigger)

             t3 = gsap.timeline({
                scrollTrigger: {
                    trigger:".hero-project-wrapper .section-seperator-top",
                    start:"top 30%",
                    end: "bottom bottom",
                }
            }).from([".hero-project-wrapper .section-seperator-top"], {
                y: 100,
                ease: "power4.out",
                duration: 0.8,
                stagger: {
                    amount: 0.5
                }
               
            })
            t3.from(".all-projects .project-item", {
                opacity: 0,
                ease: "power4.out",
                duration: 1.2,
                stagger: {
                    amount: 0.5
                },
                
                // filter: "blur(5px)"
            }, "<")
    
            
        })
       
       }, [])
    // useEffect(() => {
    //     let menuItem = document.querySelectorAll(".image-hover-container");
    //     let menuImage = document.querySelectorAll(".project__card__image");
        
    //     let items = ['25deg', '-25deg'];
        
    //     // adding eventListeners to all the menuItems.
    //     for (let i = 0; i < 3; i++) {
    //       //   image reveal animation
    //       let randomIndex = Math.floor(Math.random() * items.length);
    //       let selectedRandomItem = items[randomIndex];
    //       const animation = gsap.fromTo(menuImage[i], {
    //         opacity: 0,
    //         duration: 0.26,
    //         scale: 0,
    //         ease: "ease-in-out",
    //         transform: `rotate(${selectedRandomItem})`
    //       }, {
    //         opacity: 1,
    //         duration: 0.26,
    //         scale: 1,
    //         transform: "rotate(0deg)"
    //       });
        
    //       menuItem[i].addEventListener("mouseenter", () => animation.play());
    //       menuItem[i].addEventListener("mouseleave", () => animation.reverse());
        
    //       //   initialization
    //       animation.reverse();
    //     }
        
    //     //to move image along with cursor
    //     function moveText(e) {
    //         console.log(e.clientX, e.clientY)
    //         console.log(e.clientY)
    //         console.log("!!!")
    //         console.log(e.clientY % 20)
    //         let top_value = 0;
    //         gsap.to([...menuImage], {
    //             css: {
    //             //transform: `translate(${e.clientX}px -100px, ${e.clientY}px)`
    //             //top: e.clientY,
    //             // top: e.clientY % 20,
    //             left: e.clientX - 400,
    //             //filter: `brightness(${e.clientX})`
    //             skew: e.clientX / 10,
    //             },
    //             duration: 0.3,
    //         });
    //     }
        
    //     menuItem.forEach((el) => {
    //       el.addEventListener("mousemove", moveText);
    //     });
    // }, [])

    const [data, setData] = useState({
        desc1: "I create design solutions and user experience. Join hands",
        desc2: "to make your brand stand out.",
        trait1: "Creative, passionate, and unique",
        trait2: "are some of my key qualities which",
        trait3: "make me stand out among other",
        trait4: "designers.",
        projects: [{
            company_type: "Saas Brand",
            name: "CtrlCampus",
            description: ["Ctrl campus is a <span> SAAS brand </span> focusing on", "building ERP Products to help organizations", "digitalize their campuses." ],
            for: "UI/UX Design, Branding",
            link: "",
            link_name: "View Case Study",
            image_url: Project1Img,
            slash: "/22",
            link_to: "/ctrl-campus",
            class_name: "ctrl-campus-image",
            bg_img: Project1Img
        }, 
          
        {
            company_type: "Food Ordering",
            name: "Swiggy",
            description: ["I worked on this project to research about", "swiggies after ordering user experience. I faced", "a small issue and wanted to address it."],
            for: "UX Case Study",
            link: "",
            link_name: "View Case Study",
            image_url: Project2Img,
            slash: "/23",
            link_to: "/",
            class_name: "swiggy-image",
            bg_img: Project2Img
        }, 
        {
            company_type: "Design Studio & Media Brand",
            name: "NIWWRD",
            description: ["A UX case study in collaboration with NIWWRD. I", "worked on multiple products to solve UX issues",  "in the website."],
            for: "UI/UX Design Case Study",
            link: "",
            link_name: "View Case Study",
            image_url: Project3Img,
            slash: "/22",
            link_to: "/niwwrd",
            class_name: "niwwrd-image",
            bg_img: Project3Img
        }],
        moveDesc1: "I have a water like seamless way of getting things",
        moveDesc2: "done. A clear and transparent workflow makes me",
        moveDesc3: "work better and with ease.",
        selected_work: "I've honed my design skills with unwavering dedication. With every project, I strive to create intuitive and visually engaging user experiences. My passion for this field is not just a career choice; it's a commitment to crafting meaningful, user-centric designs that leave a lasting impact.",
        testimonials: [{
            title: "Co-Founder @Ctrlcampus",
            image: SrinivasaImg,
            name: "Srinivasa Reddy",
            description: "Ankit Deshwal , continues to be an invaluable member of our team, consistently delivering exceptional UI/UX work. Ankit's dedication to their craft, attention to detail, and collaborative spirit make them an asset to any project. I highly recommend Ankit's for their ongoing contributions."
        }, {
            title: "Soft Skill Trainer",
            image: kuljinderImg,
            name: "Kuljinder Kaur",
            description: "Ankit is a creative, enthusiastic, and highly disciplined student. He ensured timely submissions of any given task with efforts and energy. He has the zeal to learn and grow which helps him improve continuously. His vision for future is clear and he is dedicated to achieving his goals. I am sure he will do wonders in life with his innovative perspective and out of box thinking. All the best Ankit."
        }, {
            title: "Smruti Tiwari",
            image: SmrutiImg,
            name: "UI/UX Designer @",
            description: "Ankit is an exceptional UI/UX designer with a strong grasp of design principles. While I haven't worked with him directly, but his work is extremely amazing. He is dedicated to create designs that are user-centric. Ankit's input undeniably enhances the work quality. He provided me with valuable guidance in the past, highlighting his excellent mentoring abilities. I look forward to the chance to work closely with him, He's exactly the kind of person you want on your team, he is genuine and always open to learn."
        }, {
            title: "Product Designer",
            image: SomannaImg,
            name: "Somanna B U",
            description: "Ankit is a highly skilled UX/UI Designer with a passion for creating user-centered designs. His YouTube videos on design are informative and engaging. I highly recommend him for any UX/UI Design position. He's very competent and trustworthy and there's a lot more coming from him in the future."
        },
       
        
    ]
        
    })
    
    const [skill, setSkill] = useState({
        showHide: [false, false, false, false]
    })
    
    const revealSkill = (value) => {
        let reveal_state_array = skill.showHide
       
        reveal_state_array[value] = !reveal_state_array[value]
        
        setSkill({showHide: reveal_state_array})
    }



    return(
        // <section className="project-ref-container "> 
            
        //     <div className="project-ref-wrapper">
                            
        //                     {data.projects.map((element, idx) => (
                                
        //                         <div className={`project-ref-project ${((idx%2) == 0 ) ? "background-grey" : "" }   `}>
        //                             <div className="project-ref-project-wrapper  even-spacing">
        //                                 <div className="project-ref-content-wrapper">
                                    
        //                                     <div className={`project-ref-project-${idx}`}>
        //                                         <div className="project-ref-project-details-wrapper">
                                                    
        //                                             <div className="project-ref-project-name text-m">
        //                                                 <p className="company-type grey-color" >{element.company_type} </p>
        //                                                 {/* {element.desc.length? <span className="project-ref-project-desc"> &nbsp;({element.desc})</span> : ""} */}
        //                                                 <h1 className="project-ref-project-name-heading text-xxl">{element.name} </h1>
        //                                                 <div className="project-description-wrapper">
        //                                                     {element.description.map((desc, idx) => (
        //                                                         <p className="project-small-desc">{desc}</p>
        //                                                     ))}
        //                                                 </div>
        //                                                 <div className="project-redirect-link">
        //                                                     <a  href={element.link} target="_blank">{element.link_name} <span className=""><ImArrowUpRight2 /></span></a>
        //                                                 </div>
                                                        

        //                                             </div>
                                                   
        //                                         </div>
                                                
        //                                     </div>
        //                                 </div>
        //                                 <div className="project-ref-image">
        //                                     <img src={element.image_url} />
                                            
        //                                 </div>
        //                             </div>
        //                         </div>
                            
                                
        //                     ))}
        //     </div>      
                        
        //     <Clustered_GIF />
           
        // </section>

        <div style={{borderRadius: "0px 0px 20px 20px"}}>
            <section className="wrapper" >
                <div className="hero-project-wrapper ">
                    <div className="section-seperator-top pt-five ">
                        <div className="over-hide">
                            <hr className="mb-one mt-two project-line-separation"/>
                            <div className="common-flex-layout over-hide mb-three">
                                
                                <div className="title-description">
                                    <p className="mt-zero mb-zero text-xxl heading-color"> Selected Work  </p>
                                    <p className="selected-text text-s">I am currently designing beautiful products for Ctrlcampus.</p>
                                </div>
                                <div className="content-number">
                                    {/* <Button link="/work" name="My Projects" />
                                    */}
                                    <div className="content-number-wrapper">
                                        <div className="content">
                                            <p className="selected-text-desc text-1-5">{data.selected_work}</p>
                                        </div>
                                        <div className="number">
                                            <p>(02)</p>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                            </div>
                            <div className="selected-work over-hide">
                                
                                
                            </div>
                        </div>
                        
                        <div className="projects-showcase">
                            <div className="showcase-wrapper">
                                <div className="first-second-wrapper mb-one">
                                    
                                        <div className="first-project">
                                            <Link to="/ctrl-campus">
                                                <img src={Project1Img}  />
                                                <div className="project-content">
                                                    <p className="heading-color text-1-5 mb-zero">Ctrl Campus (Professional Project)</p>
                                                    <ul>
                                                        <li>Design System</li>
                                                        <li>UI Design</li>
                                                        <li>UX Design</li>
                                                    </ul>
                                            </div>
                                            </Link>
                                        </div>
                                    
                                    <div className="second-project">
                                        
                                            <img src={Project2Img} />
                                            <div className="project-content">
                                                <p className="heading-color text-1-5 mb-zero">Swiggy (UX Case Study)</p>
                                                <ul>
                                                    <li>UX Design</li>
                                                    <li>User Testing</li>
                                                    
                                                </ul>
                                            </div>
                                        
                                    </div>
                                </div>
                                <div className="third-project">
                                    <Link to="/niwwrd">
                                        <img src={Project3Img}  />
                                        <div className="project-content">
                                            <p className="heading-color text-1-5 mb-zero    ">NIWWRD (Website Redesign)</p>
                                            <ul>
                                                <li>UI Design</li>
                                                <li>UX Design</li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>

                        {/* <div className="all-projects">
                            {data.projects.map((item) => (
                                <Link className="nav-Link-react-router " to={item.link_to? item.link_to : "/ctrl-campus"}>
                                <div className=" image-hover-container ">
                                    <div className="project-item ">
                                        <div className="project">
                                            <h2 className="text-5-5 mb-zero mt-zero">{item.name} </h2>
                                            <p className="mb-zero mt-zero">{item.for}</p>
                                        </div>
                                        <div className="slash">
                                            <p className="text-xxxl">{item.slash}  <span className="current">{item.name == "CtrlCampus" ? "- Currently" : ""}</span></p>
                                        </div>
                                        <div className={item.class_name + " project__card"}>
                                            <div className="project__card__inner">
                                                <div className="project__card__image" style={{backgroundImage: `url(${item.bg_img})`, opacity: 0}}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <hr className="mb-one  project-line-separation"/>
                                </Link>
                            ))}

                        </div> */}
                    </div>
                </div>

            </section>

            {props.move ? 
            <section className="wrapper" >
                <div className="hero-move-contain">
                    <div className="hero-move  ">
                    
                        <div className="common-flex-layout over-hide mb-three">
                            
                            <div className="title-description">
                                <p className="mt-zero mb-zero text-xxl heading-color"> My Design Process  </p>
                            </div>
                            <div className="content-number">
                                {/* <Button link="/work" name="My Projects" />
                                    */}
                                    <div className="content-number-wrapper">
                                    <div className="content">
                                        <p className="selected-text-desc text-1-5">Grounded in empathy and clear problem definition, my design process culminates in the creation of intuitive and aesthetically engaging user interfaces, rigorously tested to ensure they meet user expectations.</p>
                                    </div>
                                    <div className="number">
                                        <p>(03)</p>
                                    </div>
                                    </div>
                                
                                
                            </div>
                        </div>
                        <div className="hero-move-wrapper">
                            
                            <div className="move-left">
                                <div className="move-left-wrapper">
                                    {/* <div className="over-hide">
                                        <h2 className="text-5-5 mt-zero mb-zero">How I move.</h2>
                                    </div>
                                    <div className="over-hide mt-two">
                                        <span className="mt-zero mb-zero text-l move-desc-1">{data.moveDesc1} </span>
                                        <span className="mt-zero mb-zero text-l move-desc-2">{data.moveDesc2}</span>
                                        <span className="mt-zero mb-zero text-l move-desc-3">{data.moveDesc3}</span>
                                    </div> */}
                                    <svg width="754" height="302" viewBox="0 0 754 302" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMaxYMax meet">
                                        <path className="path-2" d="M-54 272.448V57.9466C-54 42.4858 -47.6603 27.7012 -36.4604 17.0429V17.0429C-14.6575 -3.70557 19.5876 -3.70557 41.3905 17.0429V17.0429C52.5905 27.7012 58.9301 42.4858 58.9301 57.9466V251.021C58.9301 264.703 64.5404 277.787 74.4519 287.219V287.219C93.7465 305.58 124.052 305.58 143.347 287.219V287.219C153.258 277.787 158.868 264.703 158.868 251.021V57.9466C158.868 42.4858 165.208 27.7012 176.408 17.0429V17.0429C198.211 -3.70557 232.456 -3.70557 254.259 17.0429V17.0429C265.459 27.7012 271.798 42.4858 271.798 57.9466V251.021C271.798 264.703 277.409 277.787 287.32 287.219V287.219C306.615 305.58 336.92 305.58 356.215 287.219V287.219C366.126 277.787 371.737 264.703 371.737 251.021V57.9466C371.737 42.4858 378.076 27.7012 389.276 17.0429V17.0429C411.079 -3.70557 445.324 -3.70557 467.127 17.0429V17.0429C478.327 27.7012 484.667 42.4858 484.667 57.9466V251.021C484.667 264.703 490.277 277.787 500.188 287.219V287.219C519.483 305.58 549.788 305.58 569.083 287.219V287.219C578.994 277.787 584.605 264.703 584.605 251.021V57.9466C584.605 42.4858 590.944 27.7012 602.144 17.0429V17.0429C623.947 -3.70557 658.192 -3.70557 679.995 17.0429V17.0429C691.195 27.7012 697.535 42.4858 697.535 57.9466V115.647C697.535 146.832 722.815 172.112 754 172.112V172.112" stroke="#4B4B4B"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="move-right syne">
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 01. </span>
                                                <span className="text-xxl"> Empathy </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-1"  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(0)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={skill.showHide[0] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p>My initial step involves understanding everything about the project fro What I am going to design to Why am I going to design this.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 02. </span>
                                                <span className="text-xxl"> Define </span>
                                            </div>
                                            <div className="svg">
                                                <svg  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(1)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path id="path-2" fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                

                                    <div className={skill.showHide[1] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p>Now I will put everything together where it all make sense defining the problem, research, expected solution,</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 03. </span>
                                                <span className="text-xxl"> Design </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-3" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(2)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                

                                    <div className={skill.showHide[2] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p>This is the phase where I put my ideas into reality Keeping accessibility and usability in mind.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 04. </span>
                                                <span className="text-xxl"> Test </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-4" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(3)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={skill.showHide[3] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p>Now that we have everything, It is the time when I will test if my design actually makes an impact or its time to do some changes.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            : <></>}
            {props.move ?
            <section className="testimonial section-seperator-bottom">
                <hr className="mb-three mt-two project-line-separation"/>
                <div className="wrapper">
                    <div className="common-flex-layout over-hide mb-three">
                                    
                        <div className="title-description">
                            <p className="mt-zero mb-zero text-xxl heading-color"> Testimonials  </p>
                        </div>
                        <div className="content-number">
                            {/* <Button link="/work" name="My Projects" />
                            */}
                            <div className="content-number-wrapper">
                                <div className="content">
                                    <p className="selected-text-desc text-1-5">I let my work do the talking. With a diverse portfolio of independently managed projects, I've garnered feedback that speaks to the quality and impact of my design solutions. Discover what others have to say about my work.</p>
                                </div>
                                <div className="number">
                                    <p>(04)</p>
                                </div>
                            </div>
                            
                        
                        </div>
                    </div>
                    <p className="selected-text-desc text-1-5 mb-three">What people have to say about me !!</p>         
                </div>                   
              
                <div className="testimonial-cards-wrapper">
                    
                        <div className="cards">
                            <Marquee
                             autoFill="true"
                             speed={90}
                            
                             >
                                {data.testimonials.map((e) => (
                                    <Card title={e.title} description={e.description} image={e.image} name={e.name} />
                                ))}
                            </Marquee>

                        </div>
                    
                </div>  
              

            </section>
            : <></>}

        </div>
    );
    
}
export default ProjectRef;
