import React, {useState} from "react";
import './niwwrd.css';
import '../CtrlCampus/campus';
import Logos from '../../../assets/project_images/Niwwrd/logos.png';
import Overview from '../../../assets/project_images/Niwwrd/overview.png';
import Moodboard from '../../../assets/project_images/Niwwrd/Moodboard.png';
import DesignProcess from '../../../assets/project_images/Niwwrd/design_process.png';
import final1 from '../../../assets/project_images/Niwwrd/final1.png';
import final2 from '../../../assets/project_images/Niwwrd/final2.png';
import final3 from '../../../assets/project_images/Niwwrd/final3.png';
import final4 from '../../../assets/project_images/Niwwrd/final4.png';
import StudentWeb from '../../../assets/project_images/ctrlcampus/student_web.png';
import EmailFooter from "../../../shared/footer/email_footer/email_footer";
import BehanceButton from "../../../shared/Button/SeeOnBehance/behance_button";

const Niwwrd = () => {
    const [data, setData] = useState({
        
        company_desc: "We are an Indian design studio and media company. We are the top design studio of the future, and we support talented artists and designers. Everything is available here.",
        Role: "UX/UI Designer",
        context: "Design Studio & Media Brand",
        Location: "Gurugram, India",
        intro: "We are an Indian design studio and media company. We are the top design studio of the future, and we support talented artists and designers. Everything is available here.",
        overview: "NIWWRD is a design studio and media brand. The website design is not upto the mark and not matching with the current design trends. The motive was to redesign the brand website and give it a fresh look. ",
        problem_statement: "The website had a few minor flaws that needed to be fixed. This will make it possible for the company to provide new services that call for enhanced user experience and design.",
        design_process: "Both research and design-part were a part of my design process. The majority of the research was completed through interviews with key stakeholders, and site maps, wireframes, and other design elements were produced.",
        moodboard: "Describing the brand theme and creating the website design was a difficult task. I took a lot of iterations to finalize the design. due to the cluster of information there is a lot of essy stuff which should be managed.",
        final_ui_design: "The low fidelity wireframes are a portraite of the actual design which was finalized.",
        student_mobile_application: "Admin Web application consists of all the necessary features required by the administraions, organizaers, or organization heads. Features such as tracking attendance, managing fees and salaries, tracking organization count, and modues such as HR, Organizaiton, communication, attendance and many more.",
        live_website_link: "https://ctrlcampus.com/index.html",
        behance_link: "adfa"
    })
    return(<>

        <div className="full-wrapper" >
            <div className="hero-project2-container">
                <div className="project-content-wrapper">
                    <div className="pt-six pb-six">
                        <div className="content">
                            <h1 className="text-4">Niwwrd</h1>
                            <p className="mt-five text-m"> {data.company_desc}</p>
                            <div className="behance_redirect">
                                <BehanceButton name={"See on Behance"} link="#" />
                            </div>
                        </div>
                        
                    </div>
                    <div>
                    
                </div>
                   
                </div>
                
            </div>
        </div>
        <div className="wrapper">
            <div className="role-duration-location mt-two">
                <div>
                    <p>Role - <span>{data.Role}</span></p>
                </div>
                <div>
                    <p>Context - <span>{data.context}</span></p>
                </div>
                <div>
                    <p>Location - <span>{data.Location}</span></p>
                </div>
            </div>
            
        </div>
        <section className="wrapper mb-four">
            <div className="campus-introduction">
                <h1 className="text-3">
                    Introduction
                </h1>
                <hr className="project-line-seperation" />
                <p>{data.intro}</p>
                <img src={Logos} alt="LOGO'S"/>
            </div>

            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Overview
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.overview}</p>
                    </div>
                </div>
                
                <img src={Overview} alt="Overview" />
            </div>
            


            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Problem Statement
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.problem_statement}</p>
                    </div>
                </div>
                <hr className="project-line-seperation" />
            </div>

            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Design Process
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.admin_mobile_application}</p>
                    </div>
                </div>
                
                <img src={DesignProcess} alt="AdminMobile " />
            </div>


            <div className="campus-div">
                <div className="content-wrapper mb-two">
                    <div className="left">
                        <h1 className="text-3">
                            Moodboard
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.moodboard}</p>
                    </div>
                </div>
                
                <img src={Moodboard} alt="StudentWeb " />
            </div>


            <div className="campus-div">
                <div className="content-wrapper mb-two">
                    <div className="left">
                        <h1 className="text-3">
                            Final UI Design
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.final_ui_design}</p>
                    </div>
                </div>
                <img src={final1} className="final_ui_img" alt="StudentWeb " />
                <img src={final2} className="final_ui_img" alt="StudentWeb " />
                <img src={final3} className="final_ui_img" alt="StudentWeb " />
                <img src={final4} className="final_ui_img" alt="StudentWeb " />
                
            </div>

        </section>
        
        <EmailFooter/>
    </>
    )
}
export default Niwwrd;