import React, { useEffect, useLayoutEffect } from "react";
import { useState, useRef } from "react";
import './hero.css';
import { ImArrowDownRight2 } from "react-icons/im";
import { FaBeer } from 'react-icons/fa';
import Button from "../../shared/Button/button";
import BigHi from '../../assets/svgs/hi.svg';
import SmallHi from '../../assets/svgs/small_hi.svg';
import {FiArrowUpRight} from 'react-icons/fi';
import ClickMe from '../../assets/svgs/click_me.svg';
import Company1Logo from '../../assets/images/company_1_logo.jpg';
import Company2Logo from '../../assets/images/company_2_logo.png';
import SeeMore from '../../assets/svgs/see_more.svg';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { CgDarkMode } from "react-icons/cg";
const Hero = ()  =>{
    const [data, setData] = useState({
        heroHeading1: "UX/UI",
        heroHeading2: "Designer",
        desc1: "Hi, my name is Ankit Deshwal, I am a 23 years young self-taught Product ", 
        desc2: "Designer. I help brands standout on digital level. I have been crafting ",
        desc3: "amazing user experience from the last 2 years.",
        
        exp1: "I am currently designing products for",
        company_name: "Ctrlcampus",
        exp2: "a SAAS brand.  I have been working",
        exp3: "here  since the",
        company_duration: "past one year.",
        linkedin: "https://www.linkedin.com/in/ankit-deshwal/",
        behance: "https://www.behance.net/AD_designs40/projects",
        instagram: "https://www.instagram.com/methodical_ad/"

    });
     
    const [about_data, setAboutData] = useState({
        refHeading1: "I create design solutions and user experience. Join hands",
        refHeading2: "to make your brand stand out.",
        refDesc1: "Creative, passionate and unique",
        refDesc2: "are some of my key qualities which",
        refDesc3: "make me stand out amoung other",
        refDesc4: "designers."
    });

    const [hero_about, setHeroAbout] = useState({
        title1: "What I Can Offer",
        title2: "You?",
        desc1: "My specialization can bring a drastic change in",
        desc2: "your online presence.",
        right_data: [
            {title: "UI Design", desc: "Elevating user experiences through intuitive interfaces and visually captivating designs that strike the perfect balance between aesthetics and functionality."},
            {title: "UX Research", desc: "Uncovering user insights to inform data-driven design decisions and create meaningful interactions that resonate with the target audience on a profound level."}, 
            {title: "Design System", desc: "Crafting cohesive and scalable design systems that foster consistency and efficiency across all digital products, empowering teams to deliver exceptional user experiences seamlessly."}
        ]
    })


    const titleRef = useRef();
    const nav_links = useRef();
//    useLayoutEffect(() => {
   
//     // a gsap.context() lets us use scoped selector text and makes cleanup way easier. See https://greensock.com/docs/v3/GSAP/gsap.context()
   

      
       
  
//    }, []);

   useEffect(() => {

    let ctx = gsap.context(() => {

        const t1 = gsap.timeline();

            // t1.to(".svg-path", {drawSVG: 1})
            // t1.fromTo(".svg-path", { 
            //     css: {  strokeDashoffset: "0", strokeDasharray: "0"}, 
            //     ease: "power2.out",
            //     duration: 5
            // }, {css: {
            //     strokeDasharray: "772",
            //     strokeDashoffset: "0",
               
            // }})
        //hero section
            t1.to("svg .svg-path", {
                opacity: 1,
                css: {
                    animationName: "animate",
                    animationDuration: "3s",
                    animationTimingFunction: "linear",
                    animationFillMode: "forwards",
                }
            })
            t1.fromTo([".hero-heading-1 .heading-text ", ".navigation-logo"," .navigation-links span"], { 
                y: 300,
                ease: "power4.out",
                delay: 0.4,
                duration: 0.5,
                opacity: 0
            }, {
                y: 0,
                opacity: 1 
            });
            
            t1.fromTo([".desc-1", ".desc-2", ".desc-3"], {
                
                y: 300,
                ease: "power4.out",
                delay: 0.1,
                duration: 0.5,
                opacity: 1,
                stagger: {
                amount: 0.4
                }
            }, {
                y: 0,
                opacity: 1,
                stagger: {
                    amount: 0.4
                    }
            })
            t1.from(".all-links a", {
                y: 300,
                ease: "power4.out",
                delay: 0.1,
                duration: 0.7,
                stagger: {
                amount: 0.4
                }
            }, "<")
            // t1.from(".navigation span", {
            //     y: 300,
            //     ease: "power4.out",
            //     stagger: {
            //     amount: 0.4
            //     }
            // }, "<")

        gsap.registerPlugin(ScrollTrigger)
        const t2 = gsap.timeline({
            scrollTrigger: {
                trigger:".hero-about",
                start:"top 50%",
                end: "bottom bottom",
                
               
            }
        }).from([".offer-text  .title-div", ".offer-text .offer-text-desc span", , ".hero-about-left .hero-about-button", ".hero-about-left .offer-text .title-2"], {
            y: 300,
            ease: "power4.out",
            
           
        })
        t2.from([".hero-about-right .hero-about-skill p"], {
            y: 200,
            ease: "power4.out",
            stagger: {
                amount: 0.5
            }
        })


        // const t3 = gsap.timeline({
        //     scrollTrigger: {
        //         trigger:".hero-project-wrapper .section-seperator-bottom",
        //         start:"top 30%",
        //         end: "bottom bottom",
               
               
        //     }
        // }).from([".hero-project-wrapper .selected-work"], {
        //     y: 100,
        //     ease: "power4.out",
        //     duration: 0.8,
        //     stagger: {
        //         amount: 0.5
        //     }
           
        // })
        // t3.from(".hero-project-wrapper  .all-projects .project-item", {
        //     //y:200
        //     opacity: 0,
        //     ease: "power4.out",
        //     duration: 1.2,
        //     stagger: {
        //         amount: 0.5
        //     },
        //     filter: "blur(5px)"
        // }, "<")

        const t4 = gsap.timeline({
            scrollTrigger: {
                trigger:".hero-move-contain ",
                start:"top 30%",
                end: "bottom bottom",
            } }).to(".move-left-wrapper svg .path-2", {
                opacity: 1,
                css: {
                    animationName: "pathAnimation",
                    animationDuration: "2s",
                    animationTimingFunction: "linear",
                    animationFillMode: "forwards",
                }
            })
        // }).from([".move-left-wrapper h2", ".move-desc-1", ".move-desc-2", ".move-desc-3" ], {
        //     y: 100,
        //     ease: "power4.out",
        //     stagger: {
        //         amount: 0.5
        //     } 
           
        // })
       
        t4.from(".move-right .move-skill .skill", {
            y: 100,
            ease: "power4.out",
            stagger: {
                amount: 0.5
            } 
        })
        
    })
   
   })

    return(
        <>
        <div className="full-wrapper">
            <div className="wrapper">
                <div className="hero-wrapper">
                    <div className="design-title syne">
                        <h1 className="text-5-5 mb-zero"><span className="neon-color">Design</span> is the Language, </h1>
                        <h1 className="text-5-5 mt-zero">I speak!</h1>

                    </div>
                    <div className="text-mode-wrapper ">
                        <div className="design-text-description">
                            <p style={{color: "var(--grey-color)"}}>Designer / Creator</p>
                            <div className="hero-text-description  text-1-5 line-2 over-hide"  >
                                <span className=" desc-1"  >
                                    {data.desc1} 
                                </span>
                                <span className="desc-2"  >
                                    {data.desc2}
                                </span>
                                <span className="desc-3"  >
                                    {data.desc3}
                                </span>
                            </div>
                        </div>
                        <div className="light-dark-mode">
                            <p className="syne"><span className="pr-xs"><CgDarkMode /></span>Light Mode</p>

                        </div>

                    </div>
                    {/* <div className="hire-and-links">
                        <div className="hire-and-links-wrapper">
                            <div className="hero-text-description-wrapper">
                                
                                <div className="light-dark-mode">
                                    <p>Light mode</p>
                                </div>

                            </div>    
                        </div>
                    </div> */}
                </div>
            </div>


        </div>



{/* 
        <section className="wrapper">
            <div className="hero-about-wrapper ">
                    <div className="section-seperator-top pt-five">
                        <div className="hero-about ">
                                <div className="hero-about-left over-hide">
                                        <div className="offer-text over-hide">
                                            <div className="over-hide title-div">
                                                <h2 className="text-xxxl over-hide ">{hero_about.title1}</h2>
                                                <span className="text-xxxl title-2 ">{hero_about.title2}</span>
                                            </div>
                                            <div className="offer-text-desc over-hide">
                                                <span className="over-hide">{hero_about.desc1} </span><span className="over-hide">{hero_about.desc2} </span>
                                            </div>
                                        </div>
                                        <div className="hero-about-button over-hide">
                                            <Button link={"/about"} name="About Me" />
                                        </div>
                                </div>
                                <div  className="hero-about-right ">
                                    {hero_about.right_data.map((x) => (
                                        <div className="hero-about-skill over-hide">
                                            <p className="text-xxl over-hide">{x.title}</p>
                                            <p className="text-l over-hide"  style={{fontSize: '1rem'}}>{x.desc}</p>
                                        </div>
                                    ))}

                                </div>
                        </div>
                    </div>
            </div>
        </section> */}
        </>

    );
    
}
export default Hero;