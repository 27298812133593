import logo from './logo.svg';
import Main from './containers/Main/main';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './containers/About/about';
import Footer from './shared/footer/footer';
import Navbar from './shared/Navbar/navbar';
import Work from './containers/Work/work';
import Contact from './containers/Contact/contact';
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import Preload from './shared/Preloader/preload';
import "./shared/utility.css"
import LocomotiveScroll from 'locomotive-scroll';
import './App.css';
import Campus from './components/ProjectRef/CtrlCampus/campus';
import Niwwrd from './components/ProjectRef/Niwwrd/niwwrd';
import ScrollToTop from './shared/ScrollToTop/scrollToTop';
import { Toaster } from 'react-hot-toast';
import { gsap } from 'gsap';
function App() {

  const [loading, setloading] = useState(true);
  const ref = useRef(null);
  const cursor_dot_ref = useRef(null);
  const cursor_outline_ref = useRef(null);
  
  useEffect(() => {
    //.registerPlugin(ScrollSmoother, ScrollTrigger)
    
    setTimeout(() => {
      setloading(false)
    }, 4000)


  }, [])
  window.addEventListener("mousemove", (e) => {
    
    gsap.to(cursor_dot_ref.current, { duration: 0, x: e.clientX, y: e.clientY });
    // gsap.to(cursor_outline_ref.current, { delay: 0.4, x: e.clientX, y: e.clientY, cursor: "none" });
    // gsap.to(follow, 0.9, { x: e.clientX, y: e.clientY });
  });

  //locomotive scroll is now working on main page but not in others
  //probably need to 
  // do it for every component (maybe have to create a hook)


  // useEffect(() =>{
  //   const scroll = new LocomotiveScroll({
  //     el: document.querySelector('[data-scroll-container]'),
  //     smooth: true
      
  // });
  // return (() => {
  //   scroll.destroy(); 
  // })
  // }, [])

  if(loading){
    return (
      <div className='container' style={{cursor: "none", backgroundColor: "black"}}>
        <Preload />
        <div ref={cursor_dot_ref} className='cursor-dot'></div>
            
      </div>
    )
  }else {
      return (
        // data-scroll-container style={{position: "fixed", width: "100vw"}}
          <div className='container'>
            <Toaster 
            position='bottom-right'
            />
            <BrowserRouter>
              <ScrollToTop />
              <Navbar />
              <Routes>
                <Route exact path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/work" element={<Work />} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/ctrl-campus" element={<Campus/>} />
                
                <Route path="/niwwrd" element={<Niwwrd/>} />
              </Routes>
              <Footer />
            </BrowserRouter>
            <div ref={cursor_dot_ref} className='cursor-dot'></div>
            
          </div>
        
  );
  }

}

export default App;
