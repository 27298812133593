import React from "react";

import { Link } from "react-router-dom";
import './behance_button.css'

const BehanceButton = (props) => {
    return(
        <div className="button-wrapper">
        
            <a href={props.link} id="behance-button" class="hovered_animated_button cursor_hover_zeroed">
                {props.name}                
            </a>
        
    </div>
    )
}

export default BehanceButton;